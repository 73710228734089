import React, { useEffect, useState } from "react";
import "./JobForm.scss";
import { Link } from "gatsby";

const JobForm = ({ ...props }) => {
  // Gestion de l'affichage de la modal
  useEffect(() => {
    const modals = document.querySelectorAll(".modal-container");
    const overlays = document.querySelectorAll(".modal-form-overlay");
    const openModalButtons = document.querySelectorAll(".openModal");
    const closeModalButtons = document.querySelectorAll(".closeModal");

    openModalButtons.forEach((button) => {
      button.addEventListener("click", () => {
        modals.forEach((modal) => modal.classList.add("active"));
        overlays.forEach((overlay) => overlay.classList.add("active"));
      });
    });

    closeModalButtons.forEach((button) => {
      button.addEventListener("click", () => {
        modals.forEach((modal) => modal.classList.remove("active"));
        overlays.forEach((overlay) => overlay.classList.remove("active"));
      });
    });

    overlays.forEach((overlay) => {
      overlay.addEventListener("click", () => {
        modals.forEach((modal) => modal.classList.remove("active"));
        overlays.forEach((overlay) => overlay.classList.remove("active"));
      });
    });

    // Clean up function if component is unmounted
    return () => {
      openModalButtons.forEach((button) => {
        button.removeEventListener("click", () => {
          modals.forEach((modal) => modal.classList.add("active"));
          overlays.forEach((overlay) => overlay.classList.add("active"));
        });
      });

      closeModalButtons.forEach((button) => {
        button.removeEventListener("click", () => {
          modals.forEach((modal) => modal.classList.remove("active"));
          overlays.forEach((overlay) => overlay.classList.remove("active"));
        });
      });

      overlays.forEach((overlay) => {
        overlay.removeEventListener("click", () => {
          modals.forEach((modal) => modal.classList.remove("active"));
          overlays.forEach((overlay) => overlay.classList.remove("active"));
        });
      });
    };
  }, []);

  // State pour gérer les données du formulaire
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    motivations: "",
    url: "",
    file: null,
  });

  // State pour gérer le nom du fichier
  const [fileName, setFileName] = useState("Joindre un doc");

  // State pour gérer le statut du formulaire
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [succesForm, setSuccesForm] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    const form = e.target;
    const formDataObj = new FormData(form);

    try {
      const response = await fetch("/", {
        method: "POST",
        body: formDataObj,
      });

      if (response.ok) {
        setSuccesForm(true);
        setFormData({
          name: "",
          phone: "",
          email: "",
          motivations: "",
          url: "",
          file: null,
        });
        setFileName("Joindre un doc");
      } else {
        setError("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      setError("Une erreur s'est produite, veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Le fichier est trop volumineux ! (max 5MB)");
      e.target.value = "";
      setFileName("Joindre un doc");
    } else {
      setFileName(file.name);
      setFormData({ ...formData, file: file });
    }
  };

  return (
    <>
      <div className="modal-form-overlay"></div>
      <div className="modal-container">
        <button className="closeModal btn-close-job-form">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-x"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
        {!succesForm ? (
          <>
            <Form
              title={props.title}
              onSubmit={handleSubmit}
              onChange={handleChange}
              onFileChange={handleFileChange}
              isLoading={isLoading}
              fileName={fileName}
              error={error}
            />
          </>
        ) : (
          <SuccesFormView />
        )}
      </div>
    </>
  );
};

const Form = ({ title, onSubmit, onChange, onFileChange, isLoading, fileName, error }) => {
  return (
    <>
      <h3>{title}</h3>
      <h1>Prêt pour tenter l’expérience ?</h1>
      <p className="form-intro">
        Vous voulez rejoindre l’équipe ? <br />
        Envoyez-nous vos motivations et votre parcours ! Nous étudions toutes les candidatures et si
        votre profil correspond nous vous recontacterons bientôt !
      </p>
      <form
        name="jobForm"
        method="POST"
        data-netlify="true"
        enctype="multipart/form-data"
        data-netlify-honeypot="bot-field"
        onSubmit={onSubmit}
      >
        {/* Anti Bot */}
        <input type="hidden" name="form-name" value="jobForm" />
        <p hidden>
          <label>
            Ne pas remplir : <input name="bot-field" onChange={onChange} />
          </label>
        </p>
        <div className="input-style">
          <input id="name" type="text" name="name" className="empty" onChange={onChange} required />
          <label htmlFor="name">Votre nom</label>
        </div>
        <div className="input-style">
          <input id="phone" type="tel" name="phone" className="empty" onChange={onChange} />
          <label htmlFor="phone">Votre téléphone</label>
          <span className="optional-input">facultatif</span>
        </div>
        <div className="input-style">
          <input
            id="email"
            type="email"
            name="email"
            className="empty"
            onChange={onChange}
            required
          />
          <label htmlFor="email">Votre email</label>
        </div>
        <div className="input-style">
          <textarea
            name="motivations"
            id="textarea"
            className="empty"
            cols="30"
            rows="5"
            onChange={onChange}
          ></textarea>
          <label htmlFor="textarea">Vos motivations</label>
        </div>
        <div className="input-style">
          <input id="url" type="url" name="url" className="empty" onChange={onChange} />
          <label htmlFor="url">URL (behance, portfolio, github,...)</label>
          <span className="optional-input">facultatif</span>
        </div>
        <div className="input-style file">
          <input
            type="file"
            name="file"
            id="file"
            onChange={onFileChange}
            accept=".pdf,.jpg,.png"
          />
          <div>
            {/* <label htmlFor="file">
              {fileName} <i className="fa-file"></i>
            </label> */}
            <span className="optional-input">facultatif</span>
          </div>
          <span className="file-format">pdf, jpg, png, 5Mo max</span>
        </div>

        {error && (
          <p style={{ color: "#e06c65" }} className="errorMsg">
            {error}
          </p>
        )}

        <div className="footer-job-form d-md-flex justify-content-between align-items-start">
          <button className="submit-button" type="submit" disabled={isLoading}>
            {isLoading ? "Envoi en cours..." : "Je postule"}
          </button>
        </div>
      </form>
    </>
  );
};

export const SuccesFormView = () => {
  return (
    <div className="result">
      <h3>Bien reçu !</h3>
      <p>Merci pour ton envoi, nous te contactons dès que possible.</p>
      <Link href="/">Retour au site</Link>
    </div>
  );
};

export default JobForm;
